<div class="table-container">
  <table
    mat-table
    matSort
    [dataSource]="dataSource"
    (matSortChange)="performSortChange($event)"
  >
    <ng-container
      [matColumnDef]="col.key"
      *ngFor="let col of columnsSchema"
      [sticky]="col.stick"
    >
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="!col.enable_sort"
        [ngClass]="col.column_size"
      >
        {{ col.label }}
      </th>
      <td mat-cell *matCellDef="let element" style="white-space: nowrap">
        <ng-container *ngIf="col.key === 'actions'; else elseStatus">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            aria-label="Action Menu"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button
              mat-menu-item
              *ngFor="let action of actions_list"
              [disabled]="disableActionBtn(action, element)"
              (click)="parentAction(action, element)"
            >
              <span>{{ action.title }}</span>
            </button>
          </mat-menu>
        </ng-container>

        <ng-template #elseStatus>
          <ng-container *ngIf="col.key === 'status_name'; else elseDatetime">
            <mat-chip
              class="tkg-chips"
              [style.background-color]="element['status_color']"
            >
              <p class="tkg-chips-title">
                {{
                  col.key.includes(".")
                    ? element[col.key.split(".")[0]][col.key.split(".")[1]]
                    : element[col.key]
                }}
              </p>
            </mat-chip>
          </ng-container>
        </ng-template>

        <ng-template #elseDatetime>
          <ng-container *ngIf="col.type === 'datetime'; else elseText">
            {{
              col.key.includes(".")
                ? element[col.key.split(".")[0]][col.key.split(".")[1]]
                : (element[col.key] | date: col.variant)
            }}
          </ng-container>
        </ng-template>

        <ng-template #elseText>
          {{
            col.key.includes(".")
              ? element[col.key.split(".")[0]][col.key.split(".")[1]]
              : element[col.key]
          }}
        </ng-template>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    (page)="performOnChangePage($event)"
    *ngIf="showPaginator"
    [length]="totalItems"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex - 1"
    [pageSizeOptions]="pageSizeOptions"
    showFirstLastButtons
  ></mat-paginator>
</div>
