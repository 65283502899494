<div class="top-bar-filters">
  <div>
    <div>
      <mat-form-field appearance="outline">
        <mat-label>{{ "Business_model.search" | translate:{Default: 'Buscar'} }}</mat-label>
        <input matInput placeholder="" [formControl]="searchFilter" />
        <mat-icon matSuffix *ngIf="!searchFilter.value">search</mat-icon>
        <mat-icon
          matSuffix
          (click)="clearFilter($event)"
          [matTooltip]="'Business_model.name' | translate:{Default: 'Nome'}"
          ngClass="app-search-clear"
          *ngIf="searchFilter.value"
          >clear</mat-icon
        >
      </mat-form-field>
    </div>
  </div>
</div>
<app-loading-table *ngIf="showLoadingData"></app-loading-table>
<div class="message" *ngIf="showMessage">
  <p class="tkg-message">{{ message }}</p>
</div>
<app-list-empty *ngIf="showListEmpty"></app-list-empty>
<div class="mat-elevation-z4">
  <app-paginate-sort-table
    *ngIf="!showLoadingData && !showMessage"
    [displayedColumns]="displayedColumns"
    [pageIndex]="page"
    [pageSize]="pageSize"
    [dataSource]="dataSource"
    [totalItems]="totalItems"
    [columnsSchema]="columnsSchema"
    [actions]="action_list"
    (action)="action($event)"
    tableId="report_docks"
    [paginate]="paginate"
    (performOnChangePageOnParent)="performOnChangePage($event)"
    (performSortChangeOnParent)="performSortChange($event)"
  >
  </app-paginate-sort-table>
</div>
<button
  *ngIf="showAddButton"
  mat-mini-fab
  (click)="onClickAddButton()"
  class="mat-fab-bottom-right"
  color="primary"
>
  <mat-icon>add</mat-icon>
</button>
