<h1 class="mat-align-left tkg-title" mat-dialog-title>Tarefas</h1>
<div mat-dialog-content>
  <form [formGroup]="form">
    <div class="tkg-row">
      <div class="tkg-col-40">
        <mat-form-field appearance="outline" class="tkg-select-100">
          <mat-label>Modelo de Tarefa</mat-label>
          <mat-select formControlName="taskModel" (selectionChange)="onModelChange($event)">
            <mat-option *ngFor="let item of task_types" [value]="item.name">
              {{item.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="tkg-col-40">
        <mat-form-field class="tkg-input-100" appearance="outline">
          <mat-label>Nome da tarefa</mat-label>
          <input matInput placeholder="Nome da Tarefa" formControlName="name">
        </mat-form-field>
      </div>
    </div>

    <div>
      <div class="tkg-col-40"></div>
      <mat-checkbox formControlName="valetsOnly">Apenas Manobristas</mat-checkbox>
    </div>

    <div class="tkg-col-40">
      <mat-form-field class="tkg-input-100" appearance="outline">
        <mat-select formControlName="driver" [disableRipple]="true" placeholder="Digite ou escolha um motorista para vínculo.">
         
            <ngx-mat-select-search  
             placeholderLabel="Buscar motoristas"
             noEntriesFoundLabel="Nenhum resultado encontrado"
             formControlName="search">
            </ngx-mat-select-search>
          
          <mat-option *ngFor="let item of displayedDrivers" [value]="item.name || item.driver_name">
            {{item.name || item.driver_name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="tkg-input-100" appearance="outline">
        <mat-label>Observação</mat-label>
        <input matInput placeholder="Observação" formControlName="observation">
      </mat-form-field>
    </div>
  </form>
</div>

<div mat-dialog-actions align="end" class="tkg-dialog-btns">
  <button mat-raised-button type="button" color="secondary" (click)="cancel()">Fechar</button>
  <button cdkFocusInitial mat-raised-button type="submit" color="primary" style="color: white;" (click)="submit()" [disabled]="!form.valid">Salvar</button>
</div>