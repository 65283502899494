<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <h2 mat-dialog-title>
    {{ form.value.first_name ? form.value.first_name + " " +
    (form.value.last_name ? form.value.last_name : "") : data.action == "EDIT" ?
    ("Business_model.save" | translate:{Default: 'Salvar'}) :
    ("Business_model.add" | translate:{Default: 'Adicionar'}) }}
  </h2>
  <mat-dialog-content class="mat-typography">
    <div class="names">
      <mat-form-field class="name">
        <mat-label
          >{{ "Business_model.name" | translate:{Default: "Nome"} }}</mat-label
        >
        <input
          type="text"
          formControlName="first_name"
          matInput
          [placeholder]="'Business_model.name' | translate:{Default: 'Nome'}"
        />
        <mat-hint *ngFor="let error of errors.first_name" class="mat-error">
          {{ error }}
        </mat-hint>
      </mat-form-field>

      <mat-form-field class="name">
        <mat-label
          >{{ "Business_model.last_name" | translate:{Default: "Sobrenome"}
          }}</mat-label
        >
        <input
          type="text"
          formControlName="last_name"
          matInput
          [placeholder]="'Business_model.last_name' | translate:{Default: 'Sobrenome'}"
        />
        <mat-hint *ngFor="let error of errors.last_name" class="mat-error">
          {{ error }}
        </mat-hint>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field class="inputs">
        <mat-label
          >{{ "Business_model.email" | translate:{Default: "E-mail"}
          }}</mat-label
        >
        <input
          type="email"
          formControlName="email"
          matInput
          [placeholder]="'Business_model.email' | translate:{Default: 'E-mail'}"
        />
        <mat-hint *ngFor="let error of errors.email" class="mat-error">
          {{ error }}
        </mat-hint>
      </mat-form-field>
      <mat-form-field class="inputs" *ngIf="data.action == 'ADD'">
        <mat-label
          >{{ "Business_model.password" | translate:{Default: "Senha"}
          }}</mat-label
        >
        <input
          [type]="hidePassword ? 'password' : 'text'"
          formControlName="password"
          matInput
          [placeholder]="'Business_model.password' | translate:{Default: 'Senha'}"
        />
        <mat-hint *ngFor="let error of errors.password" class="mat-error">
          {{ error }}
        </mat-hint>
        <mat-icon
          matSuffix
          style="cursor: pointer"
          (click)="hidePassword = !hidePassword"
        >
          {{ hidePassword ? "visibility" : "visibility_off" }}</mat-icon
        >
      </mat-form-field>
    </div>
    <div fxLayout="row wrap">
      <div class="margin-bottom">
        <mat-form-field class="inputs">
          <mat-label
            >{{ "Business_model.groups" | translate:{Default: "Grupos"}
            }}</mat-label
          >
          <mat-select formControlName="group_id">
            <mat-option *ngFor="let group of groups" [value]="group.id">
              {{ group.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-form-field class="inputs">
        <mat-label
          >{{ "Business_model.department" | translate:{Default: "Departamento"}
          }}</mat-label
        >
        <mat-select formControlName="department_id" multiple>
          <mat-option
            *ngFor="let department of departments"
            [value]="department.id"
          >
            {{ department.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>

    <h3>
      {{ "Business_model.permissions" | translate:{Default: "Permissões"} }}
    </h3>

    <div class="container-card">
      <!-- card1 -->
      <div class="margin-bottom">
        <mat-card>
          <mat-card-content>
            <mat-slide-toggle color="primary" formControlName="is_superuser"
              ><b
                >{{ "Business_model.super_user" | translate:{Default: "Status de
                Super Usuário"} }}</b
              ></mat-slide-toggle
            >
            <mat-hint
              >{{ "Messages.all_permissions" | translate: {Default: "Indica que
              este usuário tem todas as permissões sem atribuí-las
              explicitamente."} }}</mat-hint
            >
          </mat-card-content>
        </mat-card>
      </div>
      <!-- card2 -->
      <div class="margin-bottom">
        <mat-card>
          <mat-card-content>
            <mat-slide-toggle color="primary" formControlName="is_active"
              ><b
                >{{ "Business_model.active_user" | translate:{Default: "Usuário
                Ativo"} }}</b
              ></mat-slide-toggle
            >
            <mat-hint
              >{{ "Messages.treat_active" | translate: {Default: "Indica que o
              usuário será tratado como ativo. Ao invés de excluir contas de
              usuário, desmarque isso."} }}</mat-hint
            >
          </mat-card-content>
        </mat-card>
      </div>
      <!-- card3 -->
      <div *ngIf="!is_business_partner" class="margin-bottom">
        <mat-card>
          <mat-card-content>
            <mat-slide-toggle color="primary" formControlName="is_carrier"
              ><b
                >{{ "Business_model.user_carrier" | translate:{Default: "Usuário
                de transportadora"} }}</b
              ></mat-slide-toggle
            >
            <mat-hint
              >{{ "Messages.count_to_carrier" | translate:{ Default: "Conta de
              usuário para transportadoras. Permite o gerenciamento dos
              motoristas."} }}</mat-hint
            >
          </mat-card-content>
        </mat-card>
      </div>
      <!-- card4 -->
      <div class="margin-bottom">
        <mat-card>
          <mat-card-content>
            <mat-slide-toggle color="primary" formControlName="is_partner"
              ><b
                >{{ "Business_model.user_partner" | translate:{Default: "Usuário
                de Parceiro"} }}</b
              ></mat-slide-toggle
            >
            <mat-hint
              >{{ "Messages.partner_description" | translate:{ Default: "Conta
              de usuário para parceiros. Permite que usuários associados aos
              parceiros acessem as funcionalidades do Maestro"} }}</mat-hint
            >
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div
      fxLayout="row wrap"
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
      class="tkg-row"
      *ngIf="form.get('is_carrier')?.value"
    >
      <mat-form-field class="inputs">
        <mat-label
          >{{ "Business_model.carrier" | translate:{Default: "Transportadora"}
          }}</mat-label
        >
        <mat-select
          formControlName="carriers"
          [placeholder]="'Business_model.carrier' | translate:{Default: 'Transportadora'}"
          multiple
        >
          <mat-option>
            <ngx-mat-select-search
              [formControl]="carrierFilter"
              [placeholderLabel]="'Business_model.carrier' | translate:{Default: 'Transportadora'}"
              [noEntriesFoundLabel]="'Messages.no_result' | translate:{Default: 'Nenhum resultado encontrado.'}"
            >
            </ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let carrier of carriersList" [value]="carrier.id">
            {{ carrier.name }}
          </mat-option>
        </mat-select>
        <mat-hint *ngFor="let error of errors.email" class="mat-error">
          {{ error }}
        </mat-hint>
      </mat-form-field>
    </div>
    <div
      fxLayout="row wrap"
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
      class="tkg-row"
      [style.display]="form.get('is_partner')?.value ? 'flex' : 'none'"
    >
      <mat-form-field class="tkg-input-col">
        <mat-label>Tipo de Parceiro</mat-label>
        <mat-select
          formControlName="bp_type_id"
          (selectionChange)="getBPList()"
          [placeholder]="'Business_model.partner_type' | translate:{Default: 'Tipo de Parceiro'}"
        >
          <mat-option *ngFor="let bp_type of bpTypeList" [value]="bp_type.id">
            {{bp_type.name}}
          </mat-option>
        </mat-select>
        <mat-hint *ngFor="let error of errors.email" class="mat-error">
          {{ error }}
        </mat-hint>
      </mat-form-field>
      <mat-form-field class="tkg-input-col">
        <mat-label>Parceiro</mat-label>
        <input type="text" (click)="openSimpleListModal()" (change)="openSimpleListModal()" formControlName="bp_name" matInput
          [placeholder]="'Business_model.partner_type' | translate:{Default: 'Tipo de Parceiro'}" />
        <mat-hint *ngFor="let error of errors.email" class="mat-error">
          {{ error }}
        </mat-hint>
      </mat-form-field>
    </div>

    <h3>{{ "Business_model.alert" | translate:{Default: "Alerta"} }}</h3>

    <div fxLayout="column wrap" class="checkbox" fxLayoutGap="10px">
      <mat-checkbox color="primary" formControlName="is_alert_mail"
        >{{ "Messages.receive_email" | translate:{Default: 'Receber alertas por
        e-mail'} }}</mat-checkbox
      >
      <mat-checkbox color="primary" formControlName="is_alert_phone"
        >{{ "Messages.receive_sms" | translate:{Default: 'Receber alertas por
        celular'} }}</mat-checkbox
      >
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="button" mat-button color="warn" mat-dialog-close>
      {{ "Business_model.close" | translate:{Default: "Fechar"} }}
    </button>
    <button
      type="submit"
      mat-raised-button
      color="primary"
      style="color: white"
      [disabled]="!form.valid"
    >
      {{ data.action == "EDIT" ? ("Business_model.save" | translate:{Default:
      "Salvar"}) : ("Business_model.add" | translate:{Default: "Adicionar"})}}
    </button>
  </mat-dialog-actions>
</form>
