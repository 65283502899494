import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { GenericService } from '../../generic.service';
import { RegistrationService } from '../../registration.service';
import { SupportDataService } from '../../support-data.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { YardTaskService } from 'src/app/account/shared/yard-task.service';
import { DockManagementService } from 'src/app/docks/shared/dock-management.service';
import { DriversService } from 'src/app/drivers/shared/drivers.service';

@Component({
  selector: 'app-assign-dialog-tasks',
  templateUrl: './assign-dialog-tasks.component.html',
  styleUrls: ['./assign-dialog-tasks.component.scss']
})
export class AssignDialogTasksComponent implements OnInit {

  available_tasks: any = [];
  available_docks: any = [];
  drivers: any = [];

  columnsSchema: any[] = [];
  transformData: any = {};
  transformMethods: any = {}
  displayedColumns: string[] = [];
  dataSource: any[] = [];
  task: any = {}

  form = this.formBuilder.group({
    id: ["", Validators.required],
    name: [""],
    observation: [""],
    dock_id: [""],
    notify_all: [true],
    driver_id: [""]
  });

  display_message = false;
  message = '';
  user: any;
  showTable = false;

  constructor(
    private genericService: GenericService,
    private driverService: DriversService,
    private yardTaskService: YardTaskService,
    private docksService: DockManagementService,
    public formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.getTasksList();
    this.getDocksList();
    this.getDriversList();

    if (this.data?.item?.id) {
      this.getData();
    }
  }

  getData() {
    const options = {
      search: false,
      paginate: false,
      pageSize: 0,
      page: 1,
      orderBy: '',
      sortedBy: '',
    };

    this.yardTaskService.get(this.data?.item?.id, options).subscribe((r: any) => {
      this.task = r;

      const tarefa = this.available_tasks.find((tarefa: { id: number; name: string; observation: string }) => tarefa.id === r.id);

      if (r.resource) {
        this.form.patchValue({
          id: r.id,
          name: tarefa.resource?.name,
          dock_id: r.resource?.dock_id,
          driver_id: r.resource?.driver_id,
          observation: tarefa.resource?.observation
        });
      }
    });
  }

  submit() {
    const { ...rest } = this.form?.value || {}; 

    
    const data = {
      id: this.form.value.id,
      resource: { ...rest},
      checkin_id: this.data?.item?.id,
    };

    this.yardTaskService.update(data).subscribe((r: any) => {
      this.dialogRef.close();
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  getTasksList() {
    this.yardTaskService.getAvailableTasks().subscribe((r: any) => {
      this.available_tasks = r.data;
    });
  }

  getDocksList() {
    const options = {
      sortedBy: "ASC",
      busy: false,
      maintenance: false,
      department_id: this.data?.item?.resource?.department_id,
      search: "",
    };

    this.docksService.index(options).subscribe((r: any) => {
      this.available_docks = r.data;
    });
  }

  getDriversList() {
    this.driverService.getValetsDrivers().subscribe((r: any) => {
      this.drivers = r;
    });
  }
}
