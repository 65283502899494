<mat-drawer-container [hasBackdrop]="true" style="height:inherit">
  <mat-drawer #drawer mode="over" position="end">
    <div class="close-drawer">
      <mat-icon (click)="drawer.toggle()">double_arrow</mat-icon>
      <!--  <button mat-flat-button color="primary" (click)="drawer.toggle()">

      </button> -->
      <mat-slide-toggle
        [checked]="automaticPanel"
        (click)="switchAutomaticPanel($event)"
      >
        Painel Automático &nbsp; &nbsp;
      </mat-slide-toggle>
    </div>
    <div class="filters-container">
      <mat-label>Filtros</mat-label>
      <form class="card-operation" [formGroup]="form">
        <mat-form-field appearance="outline" *ngIf="account.department_id?.length > 1"> 
          <mat-label>Departamento</mat-label>
          <mat-select formControlName="department_id" required multiple>
            <mat-option class="hidden_check" (click)="clearOperation();refreshData()">Limpar filtro</mat-option>
            <mat-option (click)="getOperations (department.id)" *ngFor="let department of departments async" [value]="department.id">
              {{department.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" >
          <mat-label>Operação</mat-label>
          <mat-select formControlName="operation_id" [disabled]="!(this.change&&(this.change?.length || 0) >0)" required multiple>
            <mat-option class="hidden_check" (click)="clearOperation();refreshData()">Limpar filtro</mat-option>
            <mat-option *ngFor="let operation of operations" [value]="operation.id">
              {{operation.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" >
          <mat-label>Status</mat-label>
          <mat-select formControlName="status_id" [disabled]="!(this.change&&(this.change?.length || 0) >0)" multiple>
            <mat-option class="hidden_check" (click)="clearStatus();refreshData()">Limpar filtro</mat-option>
            <mat-option *ngFor="let status of listStatus" [value]="status.id">
              {{status.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" >
          <mat-label>SubOperação</mat-label>
          <mat-select formControlName="suboperation_id" [disabled]="!(this.change&&(this.change?.length || 0) >0)" multiple>
            <mat-option class="hidden_check" (click)="clearSubOperation();refreshData()">Limpar filtro</mat-option>
            <mat-option *ngFor="let suboperation of suboperations" [value]="suboperation.id">
              {{suboperation.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <div class="panel-modal-container">

      <div class="div-title">
        <div>
          <img [src]="logo" class="logotipo" alt="Logotipo"/>
        </div>
        <span class="espaco_logo"></span>
        <span class="span-title">PAINEL DE STATUS</span>
        <span class="span-date">Última atualização {{ lastUpdate | date: 'short' }}</span>
        <div>
          <button mat-icon-button (click)="drawer.toggle()" id="button-options">
            <mat-label>Opções</mat-label>
            <mat-icon>more_vert</mat-icon>
          </button>
          <button mat-mini-fab (click)="cancel()" id="x">X</button>
        </div>

      </div>


      <div class="panel-modal-list-container">
        <div class="panel-modal-table">
          <table id="customers" mat-table [dataSource]="dataSource" multiTemplateDataRows>
            <ng-container *ngFor="let col of cols" [matColumnDef]="col">
              <th mat-header-cell *matHeaderCellDef class="aumentar col-5">
                {{formatHeader(col)}}
              </th>
              <td mat-cell *matCellDef="let element" class="aumentar col-5">
                <ng-container *ngIf="element[col].type === 'date'; else elseIfTemplate">
                  {{element[col].value ? formatDate(element[col].value, element[col].format) : ""}}
                </ng-container>
                <ng-template #elseIfTemplate>
                  <ng-container *ngIf="element[col].type === 'ts'; else elseIfTemplate2">
                    {{element[col].value ? formatTimeStamp(element[col].value) : ""}}
                  </ng-container>
                </ng-template>
                <ng-template #elseIfTemplate2>
                  <ng-container *ngIf="element[col].name === 'farol'; else elseTemplate">
                    <div class="farol" [ngClass]="getClassAlert(element[col].value)"></div>
                  </ng-container>
                </ng-template>
                <ng-template #elseTemplate>
                  {{element[col].value}}
                </ng-template>

              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="cols"></tr>
            <tr mat-row *matRowDef="let element; columns: cols;" class="example-element-row">
            </tr>
          </table>

          <mat-paginator
            [ngClass]="{'hide-buttons-actions': automaticPanel}"
            #paginatorPanelDriver
            [pageSize]="pageSize"
            [pageSizeOptions]="automaticPanel ? [] : [5, 10, 25, 50, 100]"
            [showFirstLastButtons]="!automaticPanel"
          >
          </mat-paginator>
        </div>

        <div style="text-align: center; margin-top: 16px;" *ngIf="useBottomLogo">
          <img style="width: 263px; height: 44px;" src="assets/img/logo-trackage-horizontal-white-new.png"
              alt="Logotipo">
        </div>

      </div>
    </div>
  </mat-drawer-content>

</mat-drawer-container>

