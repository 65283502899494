import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from "@angular/material/paginator";
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { PainelMotoristaService } from 'src/app/painel-motorista-loreal/shared/painelmotorista.service';
import { SupportDataService } from 'src/app/shared/support-data.service';
import { CheckinService } from 'src/app/checkin/shared/checkin.service';
import { timer, Subscription, merge, of as observableOf } from 'rxjs';
import { filter, tap, exhaustMap, catchError, debounceTime } from 'rxjs/operators';
import { AccountService } from "../../account/shared/account.service";
// import * as _ from "lodash";
import * as moment from "moment";

import { Checkin } from "../../checkin/checkin.interface";
import {DockManagementService} from "../../docks/shared/dock-management.service";

@Component({
  selector: 'app-dynamic-modal-panel-status',
  templateUrl: './dynamic-status-panel-modal.component.html',
  styleUrls: ['./dynamic-status-panel-modal.component.scss']
})
export class DynamicStatusPanelModalComponent implements OnInit {
  @ViewChild('paginatorPanelDriver', {static:true}) paginator: MatPaginator;

  drivers = [];
  cols:any = [];
  columns: any;

  dynamic_status_panel_default = {
    "columns": [
      {
        "key": "driver.name",
        "cols": 1,
        "name": "driver",
        "type": "string",
        "label": "Motorista",
        "enable_sort": false,
        "transform_field": false,
        "transform_method": "",
        "transform_params": []
      },
      {
        "key": "carrier.name",
        "cols": 2,
        "name": "carrier",
        "type": "string",
        "label": "Transportadora",
        "enable_sort": false,
        "transform_field": false
      },
      {
        "key": "event.resource.schedule_code",
        "cols": 3,
        "name": "schedule_code",
        "type": "string",
        "label": "ID do agendamento",
        "enable_sort": true,
        "transform_field": false,
        "transform_method": "",
        "transform_params": []
      },
      {
        "key": "resource.operation",
        "cols": 4,
        "name": "operation",
        "type": "string",
        "label": "Operação",
        "enable_sort": false,
        "transform_field": true,
        "transform_method": "showOperation",
        "transform_params": [
          "resource.operation"
        ]
      },
      {
        "key": "board_horse_cart",
        "cols": 5,
        "name": "board_horse_cart",
        "type": "string",
        "label": "Placas",
        "enable_sort": false,
        "transform_field": false
      },
      {
        "key": "resource",
        "cols": 6,
        "name": "dock",
        "type": "string",
        "label": "Vaga",
        "enable_sort": false,
        "transform_field": true,
        "transform_method": "showDockNumber",
        "transform_params": [
          "resource.dock_space_id",
          "resource.department_id"
        ]
      },
      {
        "key": "event.event_window",
        "cols": 7,
        "name": "event.event_window",
        "type": "string",
        "label": "Horário da Janela",
        "enable_sort": true,
        "transform_field": false,
        "transform_method": "",
        "transform_params": []
      },
      {
        "key": "resource.status",
        "cols": 8,
        "name": "status",
        "type": "string",
        "label": "Status",
        "enable_sort": false,
        "transform_field": true,
        "transform_method": "showStatus",
        "transform_params": [
          "resource"
        ]
      },
      {
        "key": "created_at",
        "cols": 9,
        "name": "time_passed",
        "type": "ts",
        "label": "Tempo desde o Checkin",
        "enable_sort": true,
        "transform_field": false,
        "transform_method": "",
        "transform_params": []
      }
    ]
  }

  lastUpdate: any;
  operations: any = [];
  suboperations: any = [];
  departments: any = [];
  translateSource: any;
  dialogResult: any;
  form = new UntypedFormGroup({
    department_id: new UntypedFormControl([]),
    operation_id: new UntypedFormControl([]),
    status_id: new UntypedFormControl([]),
    suboperation_id: new UntypedFormControl([])

  })

  subscription: Subscription;
  statusText: string;
  listStatus: any = [];
  dockSpaces: any = [];
  page: number = 0;
  length = 0;
  options: any = {};
  account: any;
  waitingResponse = false;
  actualPage: string;
  optionsFilter: any = [];
  filterLabels: any = [];

  logo: string;
  automaticPanel = true;
  useBottomLogo = true;
  system_client_id: number;
  allDataAvailable: boolean = false;
  showDepartments: boolean = false;
  status: any = "";
  allData: any;
  getData: string = "";
  department_id: any =[];
  operation_id: any = [];
  status_id: any = [];
  envData: any;
  rawData: any = [];
  searching = false;

  dataSource = new MatTableDataSource<Checkin>();
  endpoint: string;
  pageSize = 25;
  orderBy = 'id';
  sortedBy = 'ASC'

  vehicle_types: any = [];
  vehicleTypesLoaded = false;
  @ViewChild(MatSort) sort: MatSort;
  change: any=[];
  constructor(
    public checkinService: CheckinService,
    public painelMotoristaService: PainelMotoristaService,
    public supportDataService: SupportDataService,
    public dialogRef: MatDialogRef<any>,
    public breakpointObserver: BreakpointObserver,
    private accountService: AccountService,
    private dockManagementService: DockManagementService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.account = JSON.parse(localStorage.getItem("account") || "[]");
    this.system_client_id = this.account.system_client.id;
    if(this.account.department_id.length > 1){
      this.showDepartments = true;
    }
  }

  ngOnInit() {
    this.accountService.identity().then(identity => {
      this.account = identity;

      const useUserLogo = this.account.system_client.resource.environment.pages.dynamic_status_panel.useUserLogo;

      //Se a propriedade existe marcada como false em system_client, utilizar a logo padrão Trackage e remover a logo tkg-maestro do fundo da página
      if(useUserLogo == false) {
        this.logo = "assets/img/logo-trackage-white-no-background.png";
        this.useBottomLogo = false;
      } else {
        this.logo = this.account.system_client.resource.logo_path;
      }

      this.system_client_id = this.account.system_client.id;
    })

    this.refreshData();
    //getOperations inicial agora é chamado dentro do subscribe de getDepartmanets
    //Para garantir que vão existir departamentos quando for feita as verificações em getOperations
    this.getDepartments();
    this.getSuboperations();
    this.getListStatus();
    this.getDockSpaces();
    this.getVehicleTypes();

    this.endpoint = this.account.system_client.resource.environment.pages.dynamic_status_panel.endpoint ?
    this.account.system_client.resource.environment.pages.dynamic_status_panel.endpoint: "waits-checkin-events";

    this.pageSize = (
      this.account.system_client.resource.environment.pages.dynamic_status_panel.pageSize
      ? this.account.system_client.resource.environment.pages.dynamic_status_panel.pageSize
      : 25
    );

    this.orderBy = (
      this.account.system_client.resource.environment.pages.dynamic_status_panel.order_by ?? 'id'
    );

    this.sortedBy = (
      this.account.system_client.resource.environment.pages.dynamic_status_panel.sorted_by
      ?? 'ASC'
    );

    this.getColumns();
}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  updateDate() {
    this.lastUpdate = new Date();
  }

  getColumns() {
      this.columns =
      this.account.system_client.resource.environment.pages.dynamic_status_panel[
        "columns"
      ] || this.dynamic_status_panel_default["columns"];
  }

  formatHeader(item: any) {
    let result;

    this.columns.map((col: any) => {
      if (col.name == item) {
        result = col.label;
      }
    });

    return result;
  }

  //Traz os dados de checkInService e faz a formatação com o metodo formatData
  getAndProcessDataSource() {
    const options = {
      orderBy: this.orderBy || 'id',
      sortedBy: this.sortedBy || 'ASC',
      pageSize: this.pageSize ? this.pageSize: 25,
    };

    this.checkinService
      .checkins(this.endpoint, options)
      .subscribe((r: any) => {
        this.lastUpdate = new Date();

        r.data.forEach((element: any) => {
        element.resource['time_operation_active'] = this.calcDataAlert(0, this.lastUpdate, 0, element.resource)
      });

        this.dataSource.data = this.formatData(r.data);

      });
  }

  //Formata os dados, colocando os nomes de cada coluna em cols
  // E as informações em rows
  formatData(data: any) {
    let rows:any[] = [];
    let cols:any[] = [];

    //filtra os checkins de data com base nos departamentos disponiveis para este usuario
      data = data.filter((dt: any) => {
      return this.departments.some((dept: any) => {
      return dept.id === parseInt(dt.resource.department_id);
      });
      });

    this.columns.map((c: any) => cols.push(c.name));


    data.map((i: any) => {
      let obj: any = {};
      this.columns.map((j: any) => {
        if (j) {
          // let value = _.get(i, j.key);
          let value = this.getValue(i, j.key);
          obj[j.name] = {
            value: this.processColumns(value, j, i),
            name: j.name,
            format: j.format ? j.format : null,
            type: j.type,
            label: j.label,
          };
        }
      });
      rows.push(obj);
    });

    this.cols = cols;

    //TODO: This could be made dynamic by using matsort and passing the values here as needed
    //Matsort might not work by itself due to the nature of the dynamic table cols/rows

    //DESC sort
    rows.sort((a, b) => (a.start_at && b.start_at ? (a.start_at.value > b.start_at.value ? -1 : 1) : 1));

    return rows;
  }

  getValue(object: object, path: any) {
    return path.
    replace(/\[/g, '.').
    replace(/\]/g, '').
    split('.').
    reduce((o: any, k: any) => (o || {})[k], object);
  }

  processColumns(colValue: any, column: any, data: any) {
    if (column.transform_field) {
      const params = this.prepareParams(data, column.transform_params);
      return (this[column.transform_method as keyof this] as Function)(params);
    } else {
      return colValue;
    }
  }

  prepareParams(dataObj: any, paramArray: any) {
    let params:any = [];
    paramArray.forEach((element: any) => {
      let paramsA = element.split(".");
      let paramValue;
      if (paramsA.length > 1) {
        paramValue = paramsA.reduce((o: any, i: any) => {
          if (o) {
            if (dataObj[o]) {
              return dataObj[o][i];
            }
          }
        });
      } else {
        paramValue = dataObj[element];
      }
      params.push(paramValue);
    });

    return params;
  }

  formatDate(date: any, formatString: any) {
    //Make this dynamic later?
    //moment.locale('pt-br');

    let newDate = moment(date).format(formatString);

    return newDate;
  }


  //Função responsável pela atualização constante dos dados
  refreshData() {
      this.subscription = merge(
        timer(0, 10000).pipe(
          filter(
            ()=>this.automaticPanel && !this.waitingResponse
          ),
          tap(()=>{
            Math.ceil(this.length / this.paginator.pageSize) - this.page  >= 1  ? this.page++ : this.page = 1;
            this.options = {
              orderBy: this.orderBy || 'id',
              sortedBy: this.sortedBy || 'ASC',
              page: this.page,
              pageSize: this.paginator.pageSize || this.pageSize,
              with: 'carrier',
            };
          })
        ),
        this.paginator.page.pipe(
          tap(()=>{
            this.options = {
              orderBy: this.orderBy || 'id',
              sortedBy: this.sortedBy || 'ASC',
              pageSize: this.paginator.pageSize || this.pageSize,
              page: this.paginator.pageIndex + 1,
              with: 'carrier',
            };
          })),

        this.form.valueChanges
    )
    .pipe(
      debounceTime(500),
      tap(()=>{

        const formRawValue = this.form.getRawValue();
        const filters: any = { department_id: formRawValue.department_id.length > 0 ? JSON.stringify(formRawValue.department_id) : "",
         operation_id: formRawValue.operation_id.length > 0 ? JSON.stringify(formRawValue.operation_id) : "",
         status_id: formRawValue.status_id.length > 0 ? formRawValue.status_id.join('|') : "",
         suboperation_id: formRawValue.suboperation_id.length > 0 ? formRawValue.suboperation_id.join('|') : ""
        }

        //formRawValue.department_id.length > 0 ? this.form.get('operation_id')!.enable() : this.form.get('operation_id')!.disable()
        if (formRawValue.department_id.length > 0) {
        }
        // console.log("this", this.form.getRawValue())
        // Object.entries(this.form.getRawValue()).forEach(([key, value])=>{
        //   filters[key] = value || ''
        // });
        this.waitingResponse = true;
        this.options = {...this.options,...filters}
      }),
      exhaustMap(() => {
        return this.checkinService.indexWithEvents(this.options).pipe(
          catchError(() => {
          return observableOf({
            total: 0,
            per_page: 0,
            data: []
          });
        }));
      }),
      tap((response: Response | any) => {
        this.waitingResponse = false;
        if (!response.data.length && this.paginator.hasPreviousPage()) {
          this.paginator.previousPage();
        }

        if(this.automaticPanel)
          this.paginator.pageIndex = this.page - 1;

        this.paginator.length = response.total;
        this.length = response.total;
        this.paginator.pageSize = response.per_page;
      }))
      .subscribe({
        next: (r: any) => {
          this.updateDate();
        //this.form.value.department_id.length > 0 ? this.form.get('operation_id')!.enable() : this.form.get('operation_id')!.disable()
          if (this.account.department_id.length == 1) {
            this.options = {
              ...this.options,
              department_id: this.account.department_id[0]
            };
          }

          this.drivers = r.data;
          this.dataSource.data = this.formatData(r.data);

        }, error: (e: any) => {
          this.waitingResponse = false;
        }
      });
  }

  getOperations(department_id?: number|string) {
    if (!department_id) {
      this.operation_id = '';
      this.optionsFilter.operation_id = '';
    }

    this.supportDataService.operation().subscribe({
      next: (r: any) => {
        for (let i = 0; i < r.data.resource.length; i++) {
          if(r.data.resource[i].visible){
            this.operations.push(r.data.resource[i]);
          }
        }
        //Desabilitar o filtro de operações se não possuir uma opção selecionada no filtro de departamentos e possuir mais de um departamento cadastrado
        (this.form.value.department_id.length > 0 || this.departments.length == 1) ? this.form.get('operation_id')!.enable() : this.form.get('operation_id')!.disable()

        if (!department_id && this.account.department_id?.length == 1) {
          department_id = this.account.department_id[0];
        }

        if (department_id) {
          this.operations = this.operations.filter((item: any) => {
            return item.department_id === department_id;
          });
        }
      },
      error: (e: any) => {
        console.log("erro");
      }
    });
  }

  labelFilterEnabled() {
    this.filterLabels =
      this.account.system_client.resource.environment.pages[this.actualPage][
        "data_filters_label"
      ];
  }

  getSuboperations(operation_id?: any) {
    this.supportDataService.suboperation().subscribe({
      next:
        (r: any) => {
          this.suboperations = r.data.resource;
          if (operation_id) {
            this.suboperations = this.suboperations.filter((item: any) => {
              return item.operation_id === operation_id;
            });
          }
        },
      error: (e: any) => {
        console.log("erro");
      }
    });
  }

  getDepartments() {
    this.supportDataService.departments()
      .subscribe({
        next: (r: any) => {
          this.departments = r.data.resource;
          this.departments = this.departments.filter((item: any) => {
            return this.account.department_id.indexOf(item.id) > -1;
          })
          this.getOperations();
        }, error: (e: any) => {
          console.log('erro');
        }
      });
  }

  //Formata coluna de timestamp para o modelo 00d:00h:00m:00s
  formatTimeStamp(date: any) {
    return moment(date).locale('pt-BR').format('DD/MM/YYYY h:mm');
  }

  //Diferença entre de tempo entre dois parametros (status, now(), etc...)
  calcData(date1: any) {
    if (date1[0]) {
      let difference_ms = 0;
      let d: any;
      let hr: any;
      let min: any;
      let s: any;
      let date2 = Date.now();

      if (date1.length > 1) {
        date2 = date1[1]
        date1 = date1[0]
      }

      const dt1 = new Date(date1);
      const dt2 = new Date(date2);

      const date1_ms = dt1.getTime();
      const date2_ms = dt2.getTime();

      difference_ms = (date2_ms - date1_ms);
      s = Math.floor((difference_ms / 1000) % 60) + 's';
      min = Math.floor((difference_ms / 1000 / 60) % 60) + 'm';
      hr = Math.floor((difference_ms / 1000 / 60 / 60) % 24) + 'h';

      if ((difference_ms / 1000 / 60 / 60) > 24) {
        hr = Math.floor((difference_ms / 1000 / 60 / 60) - (Math.floor(difference_ms / 1000 / 60 / 60 / 24) * 24)) + 'h';
        d = Math.floor(difference_ms / 1000 / 60 / 60 / 24) + 'd';
      }

      const formattedTime = [
        d ? d.toString().padStart(2, "0") : "0d",
        hr.toString().padStart(2, "0"),
        min.toString().padStart(2, "0"),
        s.toString().padStart(2, "0")
      ].join(":");

      return formattedTime;
    } else {
      return "-"
    }
  }

  calcDataAlert(date1: any, date2: any, tipo: number, resource?: any) {
    if (date1 == 0) {
      date1 = resource[`${resource.status}_created_at`]
    }

    let difference_ms = 0;
    let d: string;
    let hr: string;
    let min: string;
    let s: string;

    const dt1 = new Date(date1);
    const dt2 = new Date(date2);

    const date1_ms = dt1.getTime();
    const date2_ms = dt2.getTime();

    difference_ms = (date2_ms - date1_ms);
    if (tipo === 0) {
      s = this.padLeft(Math.floor((difference_ms / 1000) % 60), '0', 2) + 's';
      min = this.padLeft(Math.floor((difference_ms / 1000 / 60) % 60), '0', 2) + 'm';
      hr = this.padLeft(Math.floor((difference_ms / 1000 / 60 / 60)), '0', 2) + 'h';

      if ((difference_ms / 1000 / 60 / 60) > 24) {
        hr = this.padLeft(Math.floor((difference_ms / 1000 / 60 / 60) - (Math.floor(difference_ms / 1000 / 60 / 60 / 24) * 24)), '0', 2) + 'h';
        d = this.padLeft(Math.floor(difference_ms / 1000 / 60 / 60 / 24), '0', 2) + 'd';
        if (resource) {
          resource['alert'] = this.verificaAlertaTempo(resource.status, hr, min, d);
        }
        return [d, hr, min, s].join(':');
      } else {
        hr = this.padLeft(Math.floor((difference_ms / 1000 / 60 / 60)), '0', 2) + 'h';
        if (resource) {
          resource['alert'] = this.verificaAlertaTempo(resource.status, hr, min);
        }
        return [hr, min, s].join(':');
      }

    } else if (tipo === 1) {


      s = this.padLeft(Math.floor((difference_ms / 1000) % 60), '0', 2);
      min = this.padLeft(Math.floor((difference_ms / 1000 / 60) % 60), '0', 2);
      hr = this.padLeft(Math.floor((difference_ms / 1000 / 60 / 60)), '0', 2);

      let retorno: string;


      if ([hr, min, s].join(':') > '00:02:10') {
        retorno = 'offline';
      } else {
        retorno = 'online';
      }
      return retorno;
    }

    return ""
  }

  padLeft(text: number, padChar: string, size: number): string {
    return (String(padChar).repeat(size) + text).substring((size * -1), size);
  }

  verificaAlertaTempo(status: any, hora: string, minuto: string, d?: any) {
    hora = hora.replace('h', '');
    minuto = minuto.replace('m', '');
    if (d) {
      return 2;
    } else if (status === 'waiting') {
      return this.verificaTempo(Number(hora), Number(minuto), 0, 15, 0, 5);
    } else if (status === 'denied') {
      return this.verificaTempo(Number(hora), Number(minuto), 0, 15, 0, 25);
    } else if (status === 'released') {
      return this.verificaTempo(Number(hora), Number(minuto), 0, 15, 0, 25);
    } else if (status === 'waiting_direction') {
      return this.verificaTempo(Number(hora), Number(minuto), 2, 0, 4, 0);
    } else if (status === 'directed_dock') {
      return this.verificaTempo(Number(hora), Number(minuto), 2, 0, 4, 0);
    } else if (status === 'loaded') {
      return this.verificaTempo(Number(hora), Number(minuto), 1, 0, 2, 0);
    } else if (status === 'outbound') {
      return this.verificaTempo(Number(hora), Number(minuto), 0, 30, 1, 0);
    } else if (status === 'finish_loaded') {
      return this.verificaTempo(Number(hora), Number(minuto), 0, 30, 1, 0);
    } else if (status === 'withdrawn') {
      return this.verificaTempo(Number(hora), Number(minuto), 1, 0, 2, 0);
    }

    return null
  }

  verificaTempo(hora: any, minuto: any, hora_media: any, minuto_media: any, hora_critica: any, minuto_critico: any) {
    if (hora > hora_critica) {
      return 2;
    } else if (hora >= hora_critica && minuto >= minuto_critico) {
      return 2;
    } else if (hora >= hora_media && minuto >= minuto_media) {
      return 1;
    } else {
      return 0;
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  showStatus(resource: any) {

    //add allDataAvailable check back here?
    if (this.listStatus.length > 0 ) {
      const st = this.listStatus.filter(
        (item: any) => item.id === resource[0].status
      );
      if (resource[0].status === "on_dock") {
        return this.getDockName(resource[0].dock_space_id, resource[0].department_id);
      } else {
        return st[0].alternate_name;
      }
    }
  }

  //resource[0] = Dock
  //resource[1] = Department
  showDockNumber(resource: any) {
    if (resource[0] > 0) {
      if (this.account.system_client.resource.environment.docks_shared === true) {
        var dn = this.dockSpaces.filter(
          (item: any) => item.id === parseInt(resource[0])
        );

        if (dn.length > 0) {
          return dn[0].name;
        }
      } else {
        if (resource.length > 1) {
          var dn = this.dockSpaces.filter(
            (item: any) => item.id === parseInt(resource[0]) && item.department_id === parseInt(resource[1])
          );

          if (dn.length > 0) {
            return dn[0].name;
          }
        }
      }
    } else {
      return "-"
    }
  }

  showOperation(event: any) {
    if (event.length > 0) {
      if (!event[0]) {
        return "";
      }
      const op = this.operations.filter((op: any) => op.id === parseInt(event[0]));
      if (op.length > 0) {
        return op[0].name;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  showDepartment(event: any) {
    if (event.length > 0) {
      if (!event[0]) return "";
      return (
        this.departments.filter((op: any) => op.id === parseInt(event[0]))[0].name ||
        ""
      );
    } else {
      return "";
    }
  }

  showSubOperation(event: any) {
    if (event.length > 0) {
      if (!event[0] || (!event[0].suboperation && !event[0].suboperation_id))
        return "";
      if (event[0].suboperation_id) {
        return (
          this.suboperations.filter(
            (op: any) => op.id === event[0].suboperation_id
          )[0].name || ""
        );
      }
      return (
        this.suboperations.filter((op: any) => op.id === event[0].suboperation)[0]
          .name || ""
      );
    } else {
      return "";
    }
  }

  getListStatus() {
    const excludedStatus:string[] = this.account?.system_client?.resource?.environment?.pages?.dynamic_status_panel?.exclude_status || [];
    this.supportDataService.status().subscribe((r: any) => {
      this.listStatus = r.data.resource;
      this.listStatus = this.listStatus.filter((item:any)=>!excludedStatus.includes(item.id));
    });
  }

  getDockSpaces() {
    const options = {
      orderBy: "id",
      sortedBy: "ASC",
      maintenance: false,
      department_id: this.account.department_id,
      search: "",
    };


    // Pega os registros do novo módulo de gestão de docas
    this.dockManagementService.index(options).subscribe((r: any) => {
      this.dockSpaces = r.data;
      //Se cliente possuir filtro de docas para essa tela, chama a função que gera a lista de docas para o filtro
      if (this.departments) {
        //Verifica se possui mais de 1 departamento, se sim popula dockSpacesFilterList
        //Com docas separadas primeiro por departamentos, caso possua apenas 1 departamento
        //dockSpacesFilterList recebe todas as docas que possuem o mesmo department_id
        if (this.departments.length > 1) {
          this.dockSpaces = JSON.parse(JSON.stringify(this.departments));
          this.dockSpaces.forEach((item: any) => {
            item['docks'] = this.dockSpaces.filter((ft: any) => ft.department_id === item.id);
          });
        } else {
          this.dockSpaces = this.dockSpaces.filter((ft: any) => ft.department_id === this.departments[0].id);
        }
      }

    });
  }

  getDockName(id: number, department: number) {
    if (id >= 0) {
      if(this.account.department_id > 1 && department) {
        let result: any[] = [];
        this.account.department_id.forEach((element: any) => {
          if(element == department)
            result = this.dockSpaces[element].filter((item: any) => item.id == id );
        });
        return result[0].name

      } else {
        const dn = this.dockSpaces.filter((item: any) => item.id == id);
        if (dn.length > 0) {
          return dn[0].name;
        }
      }
    }
  }

  getClassAlert(alert: any) {
    if (alert === 1) {
      return 'alert-medium';
    } else if (alert === 2) {
      return 'alert-critical';
    } else if (alert === 0) {
      return 'alert-normal';
    }

    return null
  }

  getVehicleTypes() {
    this.supportDataService.vehicle_type().subscribe({
      next: (r: any) => {
        this.vehicle_types = r.data.resource;
        this.vehicleTypesLoaded = true;
      },
      error: (e: any) => {
        console.log("erro");
      }
    });
  }

  showVehicleTypeDF(vtype: any) {
    const vt = this.vehicle_types.filter(
      (item: any) => item.id == vtype[0]
    );
    if (vt.length > 0) {
      return vt[0].name;
    } else {
      return "--";
    }
  }

  switchAutomaticPanel($event: any){
    $event.preventDefault();
    this.automaticPanel = !this.automaticPanel;
  }

  clearOperation() {
    this.form.get('department_id')?.setValue([]);
    this.form.get('operation_id')?.setValue([]);

    //Desabilitar o filtro de operações apenas se possuir mais de um departamento
    if (this.departments.length > 1) {
      this.form.get('operation_id')!.disable();
    }

    this.getAndProcessDataSource();
  }

  clearStatus() {
    this.form.get('status_id')?.setValue([]);
    this.getAndProcessDataSource();
  }

  protected clearSubOperation(): void {
    this.form.get('suboperation_id')?.setValue([]);
    this.getAndProcessDataSource();
  }

}
