import { data } from 'browserslist';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BusinessPartnerService } from "src/app/account/shared/business-partner.service";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, throwError } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-simple-list',
  templateUrl: './simple-list.component.html',
  styleUrls: ['./simple-list.component.scss']
})
export class SimpleListComponent implements OnInit {
  title = this.data.title;
  itens_selected: any = [];
  searchFilter: UntypedFormControl = new UntypedFormControl();
  itens_array: any = [];
  ids_selecteds: any = [];
  showGrid = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SimpleListComponent>,
    private businessPartnerService: BusinessPartnerService
  ) { }

  ngOnInit() {
    this.getData().subscribe((response: any) => {
      this.itens_array = response.data;
      this.ids_selecteds = this.data.selected_list;

      if (this.ids_selecteds.length) {
        this.checkSelectItems()
      } else {
        this.showGrid = true;
      }
    });

    this.searchFilter.valueChanges
      .pipe(
        debounceTime(1000),
        switchMap(value => this.filterItems(value))
      )
      .subscribe(filteredItems => {
        this.itens_array = filteredItems;
        if (this.ids_selecteds.length) {
          this.checkSelectItems()
        }
      });

  }

  clearFilter(event?: any) {
    if (this.searchFilter.value) {
      this.searchFilter.setValue('');
      this.getData();
    }
  }

  getData(): Observable<any> {
    return this.businessPartnerService
      .all({ bp_type_id: this.data.bp_type_id, limit: 10 }).pipe(
        map((response: any) => response),
        catchError(err => throwError(() => err || new Error('Server error')))
      );
  }

  addRemoveItem(obj: any) {
    const idx = this.itens_selected.findIndex((item: any) => item.id === obj.id);
    if (idx !== -1) {
      this.itens_selected.splice(idx, 1);
    } else {
      this.itens_selected.push(obj);
    }
  }

  filterItems(value: string): Observable<any[]> {
    let data = {
      pageSize: 10,
      page: 1,
      bp_type_id: this.data.bp_type_id,
      search: value
    };

    return this.businessPartnerService.getBysearching(data).pipe(
      map(response => response.data)
    );
  }


  isChecked(id: number) {
    let validation = false
    this.itens_selected.map((item: any) => {
      if (item.id === id) {
        validation = true
      }
    });
    return validation
  }

  checkSelectItems() {
    this.itens_array.forEach((item: any) => {
      if (this.ids_selecteds.includes(item.id)) {
        this.itens_selected.push(item);
      }
    });
    this.showGrid = true;
  }

  clearChecked() {
    this.itens_selected = []
    this.ids_selecteds = []
  }

  sendDataToComponent() {
    this.dialogRef.close({ itens_selected: this.itens_selected, saved: true });
  }
}
