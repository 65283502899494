<div class="tkg-modal-container">
  <h2 class="mat-align-center">{{ title }}</h2>

  <div class="filter-itens">
    <mat-form-field appearance="outline">
      <mat-label>{{ "Business_model.search" | translate:{Default: "Buscar"} }}</mat-label>
      <input matInput placeholder="" [formControl]="searchFilter">
      <mat-icon matSuffix *ngIf="!searchFilter.value">search</mat-icon>
      <mat-icon matSuffix (click)="clearFilter($event)" [matTooltip]="'Business_model.clean_filter' | translate:{Default:'Limpar pesquisa'}" ngClass="app-search-clear"
        *ngIf="searchFilter.value">clear</mat-icon>
    </mat-form-field>
  </div>


  <div mat-dialog-content>
    <mat-grid-list cols="1" rowHeight="80px" *ngIf="showGrid">
      <mat-grid-tile class="tkg-grid-tile" *ngFor="let item of itens_array">
        <mat-checkbox class="tkg-checkbox" [checked]="isChecked(item.id)" (click)="addRemoveItem(item)">{{ item.name }}</mat-checkbox>
      </mat-grid-tile>
    </mat-grid-list>
  </div>

  <div mat-dialog-actions align="end">
    <button type="button" mat-button color="warn" mat-dialog-close="true">{{ "Business_model.cancel" | translate:{Default: "Cancelar"} }}</button>
    <button type="button" mat-button color="warn" (click)="clearChecked()">Limpar</button>
    <button type="button" mat-button color="primary" (click)="sendDataToComponent()">Enviar</button>
  </div>
</div>
