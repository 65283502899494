import { StepsFilterService } from "./../../shared/steps-filter.service";
import { Component, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { Subscription, Observable, throwError } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { catchError, debounceTime, map } from "rxjs/operators";

// import { ReportListDialogFiltersComponent } from '../report-list-dialog-filters/report-list-dialog-filters.component';
import { MatDialog } from "@angular/material/dialog";
import { getSupportedInputTypes } from "@angular/cdk/platform";
import { GenericService } from "src/app/shared/generic.service";
import { SupportDataService } from "src/app/shared/support-data.service";
import { OptionsDialogComponent } from "src/app/checkin-loreal/options-dialog/options-dialog.component";
import { DynamicDialogComponent } from "src/app/shared/dynamic-dialog/dynamic-dialog.component";
import { BpAddDialogComponent } from "src/app/shared/bp-add-dialog/bp-add-dialog.component";
import { DialogConfirmComponent } from "src/app/shared/dialog-confirm/dialog-confirm.component";
import { BusinessPartnerService } from "src/app/account/shared/business-partner.service";
import { FlowDialogComponent } from "src/app/shared/flow/flow-dialog.component";
import { YardTaskService } from "src/app/account/shared/yard-task.service";
import { AddDialogTasksComponent } from "src/app/shared/dialog-tasks/add-dialog-tasks/add-dialog-tasks.component";
import { GenericViewMapDialogComponent } from "../generic-view-map-dialog/generic-view-map-dialog.component";
import { data } from "browserslist";
import { error } from "console";
@Component({
  selector: "app-generic-view-list",
  templateUrl: "./generic-view-list.component.html",
  styleUrls: ["./generic-view-list.component.scss"],
})
export class GenericViewListComponent {
  pageParam: string;
  searchWatcher: Subscription;
  search: string = "";
  page: number = 1;
  pageSize: number = 10;
  orderBy: string;
  sortedBy: string;
  showListEmpty = false;
  showLoadingData = true;
  dataSource = new MatTableDataSource<any>();
  totalItems: number;
  displayedColumns: string[];
  columnsSchema: any[] = [];
  searchFilter: UntypedFormControl = new UntypedFormControl();
  searching = false;
  paginate = true;
  appliedFilters: any;
  adjustedFilters: any;
  filterWatcher: any;
  message: string;
  showMessage = false;
  action_list: any[] = [];
  addButtonOptions: any;
  showAddButton = false;
  constructor(
    private genericService: GenericService,
    private supportDataService: SupportDataService,
    private businessPartnerService: BusinessPartnerService,
    private yardTaskService: YardTaskService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private stepsFilterService: StepsFilterService,
    private http: HttpClient,
  ) { }

  ngOnInit() {
    // this.pageParam = this.route.snapshot.paramMap.get("page") || "";
    this.searchFilter.valueChanges
      .pipe(
        debounceTime(500)
      )
      .subscribe(() => {
        this.searching = true;
        this.search = this.searchFilter.value;
        this.getData();
      });

    // Captura a mudança de parâmetro da página
    this.route.paramMap.subscribe((params) => {
      console.log("aqui");
      console.log(this.pageParam);
      console.log(params);
      this.dataSource.data = [];
      if (
        this.pageParam &&
        this.pageParam != this.route.snapshot.paramMap.get("page")
      ) {
        console.log("pagina diferente");
        this.displayedColumns = [];
        this.columnsSchema = [];
        this.action_list = [];
      }
      this.message = "";
      this.showMessage = false;
      this.pageParam = this.route.snapshot.paramMap.get("page") || "";
      // this.showLoadingData = true;
      this.getData();
    });
    // this.getData();

    this.filterWatcher = this.stepsFilterService.show.subscribe(
      (filter: any) => {
        this.showDialogFilters();
      },
    );
  }

  getData() {
    const options = {
      search: this.search,
      paginate: true,
      pageSize: this.pageSize || 10,
      page: this.page || 1,
      orderBy: this.orderBy || "",
      sortedBy: this.sortedBy || "",
      ...this.adjustedFilters,
    };

    if (this.showMessage) {
      this.showMessage = false;
    }

    this.showLoadingData = true;
    this.genericService.getData(this.pageParam, options).subscribe((r: any) => {
      if (!r.error) {
        this.showLoadingData = this.dataSource.data.length === 0;
        if (r.data.length > 0) {
          this.dataSource.data = r.data;

          if (this.columnsSchema.length === 0) {
            console.log("Define schema pela primeira vez");
            this.columnsSchema = r.schema;
            this.displayedColumns = this.columnsSchema.map(
              (col: any) => col.key,
            );
            // Get Action list
            this.getActionList(this.displayedColumns);
            this.getAddButton();
            this.totalItems = r.total;
            this.showLoadingData = false;
            this.showListEmpty = r.data.length === 0 ? true : false;
            this.paginate = r.data.length > 0;
          } else {
            console.log("schema já definido");
            this.totalItems = r.total;
            this.showLoadingData = false;
            this.showListEmpty = r.data.length === 0 ? true : false;
            this.paginate = r.data.length > 0;
          }
        } else {
          this.totalItems = r.total;
          this.paginate = r.data.length > 0;
          this.showLoadingData = false;
          this.message = "Nenhum registro encontrado!";
          this.showMessage = true;
          this.getAddButton();
        }
      } else {
        this.showLoadingData = false;
        this.message = r.message;
        this.showMessage = true;
      }
    });
  }

  // clearFilter(event?:any) {
  //   if (this.searchFilter.value) {
  //     this.searchFilter.setValue("");
  //   }
  // }

  getActionList(keyList: any) {
    if (keyList.includes("actions")) {
      this.supportDataService
        .generic({ name: `view-${this.pageParam}_actions` })
        .subscribe((r: any) => {
          this.action_list = r.data.resource;
        });
    }
  }

  getAddButton() {
    this.supportDataService
      .generic({ name: `view-${this.pageParam}_add_button` })
      .subscribe((r: any) => {
        if (r.data) {
          this.showAddButton = true;
          this.addButtonOptions = r.data.resource;
        }
      });
  }

  performOnChangePage(event: any) {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.getData();
  }

  performSortChange(event: any) {
    this.orderBy = event.active;
    this.sortedBy = event.direction;
    this.getData();
  }

  ngOnDestroy() {
    // this.searchWatcher.unsubscribe();
  }

  showDialogFilters() {
    // const dialogRef = this.dialog.open(ReportListDialogFiltersComponent, {
    //   data: {page: this.pageParam, fts: this.appliedFilters}
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   if (result) {
    //     // Aplica os filtros
    //     this.appliedFilters = result;
    //     // Conta a quantidade de filtros e publica no serviço
    //     this.countNumberOfFilters(this.appliedFilters);
    //     // Busca os dados com os filtros aplicados
    //     console.log(this.appliedFilters);
    //     this.adjustFilters();
    //   }
    // });
  }

  countNumberOfFilters(filters: any) {
    const filtersKeys = Object.keys(this.appliedFilters);
    let qtdFts: any[] = [];
    filtersKeys.forEach((key: string) => {
      if (this.appliedFilters[key]) {
        if (Array.isArray(this.appliedFilters[key])) {
          if (this.appliedFilters[key].length > 0) {
            qtdFts.push(1);
          }
        } else if (this.appliedFilters[key] != null) {
          qtdFts.push(1);
        }
      }
    });
    this.stepsFilterService.onChange(qtdFts.length.toString(), "");
  }

  adjustFilters() {
    let filter: any = {};
    let localFilters = JSON.parse(JSON.stringify(this.appliedFilters));
    localFilters.forEach((item: any) => {
      const op = item.op;
      delete item.filter_name;
      delete item.value_name;
      delete item.op;
      let key = Object.keys(item)[0];
      let key_op = `${key}${op}`;
      filter[key_op] = item[key];
    });
    this.adjustedFilters = filter;
    this.getData();
  }

  onClickAddButton() {
    const options = this.addButtonOptions;
    const key = options.action as keyof typeof this;
    (this[key] as Function)(options, { id: null });
  }

  getOptionsDialog(action: any, item: any) {
    const dialogRef = this.dialog.open(OptionsDialogComponent, {
      panelClass: [action.dialog_size],
      disableClose: false,
      data: {
        title: action.title ? action.title : "Preencha os dados",
        form_name: action.dynamic_form_name,
        message: action.dialogue_message,
        item: item ? JSON.parse(JSON.stringify(item)) : {},
        action: action,
        id: item.id,
      },
    });

    dialogRef.afterClosed().subscribe((action: any) => {
      if (item) {
        const key = action.action as keyof typeof this;
        (this[key] as Function)(action, { id: null });
      } else {
      }
    });
  }

  getDynamicForm(action: any, item: any) {
    const dialogRef = this.dialog.open(DynamicDialogComponent, {
      panelClass: ["dialog-medium", "dialog-fullscreen"],
      disableClose: false,
      data: {
        title: action.title ? action.title : "Preencha os dados",
        form_name: action.dynamic_form_name,
        message: action.dialogue_message,
        item: item ? JSON.parse(JSON.stringify(item)) : {},
        action: action,
        id: item.id,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getData();
    });
  }

  getGenericMapDialog(action: any, item: any) {
    const dialogRef = this.dialog.open(GenericViewMapDialogComponent, {
      panelClass: ["dialog-medium", "dialog-fullscreen"],
      disableClose: false,
      data: {
        title: action.title ? action.title : "Preencha os dados",
        form_name: action.dynamic_form_name,
        message: action.dialogue_message,
        item: item ? JSON.parse(JSON.stringify(item)) : {},
        action: action,
        id: item.id,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getData();
    });
  }

  addEditBPForm(action: any, item: any) {
    const dialogRef = this.dialog.open(BpAddDialogComponent, {
      panelClass: ["dialog-medium", "dialog-fullscreen"],
      disableClose: false,
      data: {
        title: action.title
          ? action.title
          : item.id
            ? `Editar ${this.pageParam}`
            : "Preencha os dados",
        message: action.dialogue_message,
        item: item ? JSON.parse(JSON.stringify(item)) : {},
        action: action,
        id: item.id,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getData();
    });
  }

  addTaskForm(action: any, item: any) {
    const dialogRef = this.dialog.open(AddDialogTasksComponent, {
      panelClass: ["dialog-medium", "dialog-fullscreen"],
      disableClose: false,
      data: {
        title: action.title
          ? action.title
          : item.id
            ? `Editar ${this.pageParam}`
            : "Preencha os dados",
        message: action.dialogue_message,
        item: item ? JSON.parse(JSON.stringify(item)) : {},
        action: action,
        id: item.id,
        origin: 'task_id',
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getData();
    });
  }

  deleteTask(action: any, item: any) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      panelClass: ["dialog-small"],
      disableClose: false,
      data: {
        title: action.title
          ? action.title
          : item.id
            ? `Apagar ${this.pageParam}`
            : "Preencha os dados",
        message: "Tem certeza que deseja apagar este registro?",
        id: item.id,
      },
    });
    dialogRef.afterClosed().subscribe((r: any) => {
      if (r) {
        this.yardTaskService.delete(item.id).subscribe(() => {
          this.getData();
        });
      }
    });
  }

  addEditFlow(action: any, item: any) {
    const dialogRef = this.dialog.open(FlowDialogComponent, {
      panelClass: ["dialog-medium", "dialog-fullscreen"],
      disableClose: false,
      data: {
        title: action.title
          ? action.title
          : item.id
            ? `Editar ${this.pageParam}`
            : "Preencha os dados",
        manage: true,
        message: action.dialogue_message,
        item: item ? JSON.parse(JSON.stringify(item)) : {},
        action: action,
        id: item.hasOwnProperty("id") ? item.id : null,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getData();
    });
  }

  deleteBP(action: any, item: any) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      panelClass: ["dialog-small"],
      disableClose: false,
      data: {
        title: action.title
          ? action.title
          : item.id
            ? `Apagar ${this.pageParam}`
            : "Preencha os dados",
        message: "Tem certeza que deseja apagar este registro?",
        id: item.id,
      },
    });
    dialogRef.afterClosed().subscribe((r: any) => {
      console.log(r);
      console.log(item.id);
      if (r) {
        this.businessPartnerService.delete(item.id).subscribe(() => {
          this.getData();
        });
      }
    });
  }

  deleteSD(action: any, item: any) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      panelClass: ["dialog-small"],
      disableClose: false,
      data: {
        title: action.title
          ? action.title
          : item.id
            ? `Apagar ${this.pageParam}`
            : "Preencha os dados",
        message: "Tem certeza que deseja apagar este registro?",
        id: item.id,
      },
    });

    dialogRef.afterClosed().subscribe((r: any) => {
      if (r) {
        let params: any = item;
        const actionDef: any = this.action_list.filter(
          (item: any) => item.action == action,
        );
        console.log(actionDef);
        if (actionDef.length > 0) {
          actionDef[0].params.forEach((i: any) => {
            params = { ...i, ...params };
          });
        }
        this.supportDataService.deleteItemOnList(params).subscribe((r: any) => {
          this.getData();
        });
      }
    });
  }

  getGenericConfirm(action: any, item: any){

      const dialogRef = this.dialog.open(DialogConfirmComponent, {
        data: {
          title: action.title,
          message: action.dialogue_message,
          btnConfirm: action.btnConfirm ? action.btnConfirm : "Ok",
          btnCancel: action.btnCancel ? action.btnCancel : "Fechar",
          id: item.id,
          target: action.target
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.genericAPIdestroy(item.id, action.target).subscribe((r : any) => {
            console.log(r)
            if(r.error){
              const warn = this.dialog.open(DialogConfirmComponent, {
                data: {
                  title: "Erro ao apagar registro",
                  message: r.message,
                  btnConfirm: action.btnConfirm ? action.btnConfirm : "Ok",
                  btnCancel: action.btnCancel ? action.btnCancel : "Fechar",
                },
              });
            }else{
              this.getData();
            }

          });
        } else{
          this.getData();
        }
      });

  }

  action($event: any) {
    console.log($event);
    const key = $event[0].action as keyof typeof this;

    (this[key] as Function)({ ...$event[0] }, { ...$event[1] });
  }

  genericAPIdestroy(id: string, endpoint?: string) {
    const url = `${environment.apiUrl}/${endpoint}/${id}`;

    return this.http.delete(url).pipe(
      map((response: Response | any) => response || {}),
      catchError((err) => throwError(err || "Server error")),
    );
  }

  clearFilter(event?: any) {
    if (this.searchFilter.value) {
      this.searchFilter.setValue("");
    }
  }
}
