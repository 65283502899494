import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort, MatSortable } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DriversService } from "../shared/drivers.service";
import { DatePipe } from "@angular/common";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Driver } from "../drivers.interface";
import { TranslateService } from "@ngx-translate/core";
// import { MatSortModule } from "@angular/material/sort";
import { DriversEditComponent } from "../drivers-edit/drivers-edit.component";
import { DriversCnhComponent } from "../drivers-cnh/drivers-cnh.component";
import { UntypedFormControl } from "@angular/forms";
import {
  catchError,
  map,
  startWith,
  switchMap,
  tap,
  debounceTime,
  distinctUntilChanged,
  filter,
  takeUntil,
  concatMap,
} from "rxjs/operators";
import { merge, of as observableOf, Subscription, Subject } from "rxjs";
// import { fromEvent, of } from 'rxjs';
import { CarrierService } from "src/app/carrier/shared/carrier.service";
import { DriversFotoPerfilComponent } from "../drivers-foto-perfil/drivers-foto-perfil.component";
import { BusinessPartnerTypeService } from "src/app/account/shared/business-partner-type.service";
import { BusinessPartnerService } from "src/app/account/shared/business-partner.service";

@Component({
  selector: "app-drivers-list",
  templateUrl: "./drivers-list.component.html",
  styleUrls: ["./drivers-list.component.scss"],
})
export class DriversListComponent implements OnInit {
  pageSize: number;
  length: number;
  displayedColumns: string[];
  showListEmpty = false;
  breakpoints = Breakpoints;
  dataSource = new MatTableDataSource<Driver>();
  dataSourceAll = new MatTableDataSource<Driver>();
  // data: Driver[] = [];
  translateSource: any;
  haveValetDriver = false;
  isSuperUser: boolean;
  isCarrier: boolean;
  isAvailableTomorrow: boolean;
  carrierId: number;
  protected _onDestroy = new Subject<void>();
  isSearching: boolean;
  search: string;
  searchFilter: UntypedFormControl = new UntypedFormControl();
  searching = false;
  filterCarrierSelected: UntypedFormControl = new UntypedFormControl();
  filterStatusSelected: UntypedFormControl = new UntypedFormControl();
  account: any;
  partnerTypeId: any;

  carriers: any = [];
  listStatus: any = [
    { active: true, name: "Ativo" },
    { active: false, name: "Desativado" },
  ];

  pageEvent: PageEvent;

  @Output() reloadEvent = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild("searchInput") searchInput: ElementRef;

  constructor(
    private carrierService: CarrierService,
    public driversService: DriversService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private datePipe: DatePipe,
    public breakpointObserver: BreakpointObserver,
    private translate: TranslateService,
    private businessPartnerTypeService: BusinessPartnerTypeService,
    private BusinessPartnerService: BusinessPartnerService
  ) {
    this.isSearching = false;
    this.account = JSON.parse(localStorage.getItem("account") || "[]");
    this.isSuperUser = this.account.is_superuser;
    this.isCarrier = this.account.is_carrier;
    this.carrierId = this.account.carrier_id;
    this.breakpointObserver
      .observe([Breakpoints.Handset, Breakpoints.Tablet, Breakpoints.Web])
      .subscribe((result) => {
        if (result.matches) {
          this.activateLayout();
        }
      });

    translate.get("Driver.driversList").subscribe((res: string) => {
      this.translateSource = res;
    });
  }

  getSearch(event: any) {
    let val = event.target.value;

    this.dataSource.data = this.dataSourceAll.data.filter((item: any) => {
      return (
        item.name.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
        item.document.indexOf(val) > -1 ||
        item.resource.cnh.indexOf(val) > -1
      );
    });
  }

  ngOnInit() {
    this.sort.sort(<MatSortable>{
      id: "driver_drivers.updated_at",
      start: "desc",
    });
    const account = JSON.parse(localStorage.getItem("account") || "[]");
    this.haveValetDriver =
      account.system_client.resource.environment.allow_valet === true;
    this.isAvailableTomorrow =
      account.system_client.resource.environment.available_tomorrow ?? false;
    this.loadDrivers();
    this.getCarriers();
  }

  getFilterCarrier(carrier_id: any) {
    this.searching = true;
    const options = {
      with: "carrier",
      orderBy: this.sort.active ? this.sort.active : "updated_at",
      sortedBy: this.sort.direction ? this.sort.direction : "DESC",
      pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
      page: this.paginator.pageIndex + 1,
      carrier_id: carrier_id,
      search: this.searchFilter.value ? `${this.searchFilter.value}` : "",
      active: this.filterStatusSelected.value != null ? this.filterStatusSelected.value : '',
    };
    //console.log('Status: ', this.filterStatusSelected.value)
    return this.driversService.index(options).subscribe((r: any) => {
      this.dataSource.data = r.data;
    });
  }

  getFilterStatus(status: any) {
    this.searching = true;
    const options = {
      with: "carrier",
      orderBy: this.sort.active ? this.sort.active : "updated_at",
      sortedBy: this.sort.direction ? this.sort.direction : "DESC",
      pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
      page: this.paginator.pageIndex + 1,
      active: status,
      carrier_id: this.filterCarrierSelected.value ? this.filterCarrierSelected.value : '',
      search: this.searchFilter.value ? `${this.searchFilter.value}` : "",
    };
    return this.driversService.index(options).subscribe((r: any) => {
      this.dataSource.data = r.data;
    });
  }

  activateLayout() {
    const account = JSON.parse(localStorage.getItem("account") || "[]");
    let end = ["status"];
    let columns = ["actions", "name", "cpf", "phone", "carrier", "cnh"];
    if (account.system_client.resource.environment.allow_valet)
      columns.push("valet");

    if (account.system_client.resource.environment.available_tomorrow )
      columns.push("availabletomorrow");

    columns = [...columns, ...end];
    console.log(columns, "columns");
    this.displayedColumns = columns;
    console.log(this.displayedColumns);
  }

  loadDrivers() {
    /// Ao utilizar a busca
    //console.log('Ao utilizar a busca');
    this.searchFilter.valueChanges.pipe(
      debounceTime(500),
      switchMap(() => {
        this.searching = true;
        const options = {
          with: "carrier",
          orderBy: this.sort.active ? this.sort.active : "name",
          sortedBy: this.sort.direction ? this.sort.direction : "DESC",
          pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
          page: this.searchFilter.value ? "" : this.paginator.pageIndex + 1,
          search: `${this.searchFilter.value}` || "",
          active: this.filterStatusSelected.value != null ? this.filterStatusSelected.value : '',
          carrier_id: this.filterCarrierSelected.value ? this.filterCarrierSelected.value : '',
        };
        //console.log('Options com busca: ', options)
        return this.driversService.index(options);
      }),
      map((response: any) => {
        this.paginator.length = response.total;
        this.paginator.pageSize = response.per_page;
        return response.data;
      }),
      catchError(() => {
        this.searching = false;
        return observableOf([]);
      })
    ).subscribe((data) => {
      this.dataSource.data = data;
    });

    /// Ao entrar na página
    //console.log('Ao entrar na página');
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page, this.reloadEvent)
      .pipe(
        startWith({}),
        switchMap(() => {
          const options = {
            with: "carrier",
            orderBy: this.sort.active,
            sortedBy: this.sort.direction,
            page: this.paginator.pageIndex + 1,
            pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
            carrier_id: this.filterCarrierSelected.value ? this.filterCarrierSelected.value : '',
            active: this.filterStatusSelected.value != null ? this.filterStatusSelected.value : '',
            search: this.searchFilter.value ? `${this.searchFilter.value}` : "",
          };
          //console.log('Options sem busca: ', options)
          return this.driversService.index(options);
        }),
        tap((response: Response | any) => {
          if (!response.data.length && this.paginator.hasPreviousPage()) {
            this.paginator.previousPage();
          }

          this.paginator.length = response.total;
          this.paginator.pageSize = response.per_page;
        }),
        map((response: Response | any) => {
          return response.data;
        }),
        catchError(() => {
          return observableOf([]);
        })
      )
      .subscribe((data) => {
        this.dataSource.data = data;
        this.dataSourceAll.data = data;
      });
  }

  editarDriver(event: any, item: any, action: string) {
    if (!item) {
      item = {
        //Valor inicial para que a checkbox de transportadora padrão inicialize marcada (true) ao abrir o dialog para criar um novo motorista apenas
        default_carrier: true,
        resource: {
          cpf: null,
          cnh: null,
          carrier_id: this.carrierId != 0 ? this.carrierId : null,
          phone: null,
          //Propriedade de formControl, é inicializada como null, e no dialog copia seu valor da default_carrier acima que está fora do resource
          default_carrier: null,
        },
      };
    }

    const dialogRef = this.dialog.open(DriversEditComponent, {
      panelClass: ["dialog-small"],
      disableClose: true,
      data: {
        item: JSON.parse(JSON.stringify(item)),
        action: action,
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        let act = "";
        if (action === "EDIT") {
          act = "alterado";
        } else {
          act = "criado";
        }
        this.snackBar.open(`Motorista ${act} com sucesso!`, "OK", {
          duration: 3000,
          horizontalPosition: "left",
          verticalPosition: "bottom",
        });
      }
      this.loadDrivers();
    });
  }

  verCNH(event: any, item: any, action: string) {
    const dialogRef = this.dialog.open(DriversCnhComponent, {
      height: "560px",
      width: "528px",
      disableClose: true,
      data: {
        item: {
          driver: JSON.parse(JSON.stringify(item)),
        },
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      this.loadDrivers();
    });
  }

  verFoto(event: any, item: any, action: string) {
    const dialogRef = this.dialog.open(DriversFotoPerfilComponent, {
      height: "560px",
      width: "528px",
      disableClose: true,
      data: {
        item: {
          driver: JSON.parse(JSON.stringify(item)),
        },
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      this.loadDrivers();
    });
  }

  showStatus(item: any) {
    return this.translateSource.status[item.resource.status];
  }

  getCarriers() {
    const options = {
      page: 1,
      orderBy: "name",
      sortedBy: "ASC",
      pageSize: 1000,
    };

    if (this.account.system_client.resource.is_business_partner) {
      this.businessPartnerTypeService.all().pipe(
        concatMap((res: any) => {
          this.partnerTypeId = res.data.find((item: any) => item.ref_id === 'carrier_id');
          return this.BusinessPartnerService.all(this.partnerTypeId);
        })
      ).subscribe((carriers: any) => {
        this.carriers = carriers.data;
      });
    } else {
      // Retorne o Observable diretamente do service
      this.carrierService.index(options).subscribe((r: any) => {
        this.carriers = r.data;
      });
    }
  }

  clearFilter(event?: any) {
    this.searching = true;
    if (this.searchFilter.value) {
      this.searchFilter.setValue("");
    }
    const options = {
      with: "carrier",
      orderBy: this.sort.active ? this.sort.active : "updated_at",
      sortedBy: this.sort.direction ? this.sort.direction : "DESC",
      pageSize: this.paginator.pageSize ? this.paginator.pageSize : 10,
      page: this.paginator.pageIndex + 1,
    };
    return this.driversService.index(options).subscribe((r: any) => {
      this.dataSource.data = r.data;
      this.searching = false;
    });
  }

  alterPage(event: any) {
    this.pageEvent = event;
    this.paginator.pageIndex = event.pageIndex;
    this.loadDrivers();
  }
}
