<ng-template #menu>
  <div
    *ngIf="isOnTrial"
  >
    <span class="trial-description">{{ "Messages.trial" | translate:{Default: 'Trial: x dias restantes'} }}</span><span>&nbsp;&#160;|&#160;</span>
  </div>
</ng-template>
<div class="notify_card" *ngFor="let notify of notifications">
  <div>
    <span class="notify-description">
      {{ notify.resource.message }}
    </span>
    <div class="fill">
    </div><i class="material-icons icon_cor36">query_builder</i>
  </div>
</div>

<mat-tab-group mat-align-tabs="start" [selectedIndex]="dashboardInitialSelectedIndex">
  <mat-tab [label]="'Business_model.dashboard_schedule' | translate:{Default: 'Dashboard Agendamento'}" *ngIf="schedulingDashboard">
    <!-- TODO FIX -->
    <form [formGroup]="form" class="form-container">
      <formly-form
        [model]="model"
        [fields]="fields"
        [options]="options"
        [form]="form"
      ></formly-form>
    </form>

    <div class="grid grid-dash-simple">
      <card-dynamic *ngFor="let data of simple_dash_data"
        [layout]=1
        card="card_dash_mv"
        [data]="data"
        [options]="{}"
      >
      </card-dynamic>
    </div>

    <div class="grid grid-dash-graphic">
      <card-dynamic *ngFor="let data of graphic_dash_data"
        [layout]=2
        card="card_dash_mv"
        [data]="data"
        [options]="{}"
      >
      </card-dynamic>
    </div>
  </mat-tab>

  <mat-tab *ngIf="patioManagementDashboard" [label]="'Business_model.dashboard_yard' | translate:{Default: 'Dashboard Gestão de Pátio'}" [ngSwitch]="dashboardComponent">
    <app-default-dashboard-list *ngSwitchCase="'default_dashboard'"></app-default-dashboard-list>
    <app-loreal-dashboard-list *ngSwitchCase="'loreal_dashboard'"></app-loreal-dashboard-list>
    <app-dashboard-dynamic-structure *ngSwitchCase="'dynamic_dashboard'"></app-dashboard-dynamic-structure>
    <div class="tab2" *ngSwitchDefault>
      <div>
        <p>{{ "Messages.plan" | translate:{Default: 'Plano Atual: free-trial - Agendamento'} }}</p>
        <p>{{ "Messages.new_features" | translate:{Default: 'Você descobriu novas funcionalidades!'} }}</p>
        <p>{{ "Messages.othes_features" | translate:{
          Default: 'Entre em contato com nossa equipe e conheça outras funcionalidades da nossa plataforma.'} }}
        </p>
        <p>{{ "Messages.marketing__message" | translate:{
          Default: 'Visibilidade de ponta a ponta de toda a sua operação em um só lugar.'} }}
        </p>
        <br>
        <br>
        <button mat-flat-button color="primary">{{ "Business_model.contact_time" | translate:{Default: 'CONTATE NOSSA EQUIPE'} }}</button>
      </div>
      <div>
        <img src="assets/img/kpi2.png" />
      </div>
    </div>
  </mat-tab>
</mat-tab-group>


