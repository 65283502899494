import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  AfterViewInit,
  ViewEncapsulation,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatTableModule } from "@angular/material/table";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import {
  MatPaginator,
  MatPaginatorModule,
  PageEvent,
} from "@angular/material/paginator";
import { MatSortModule, Sort } from "@angular/material/sort";
import { MatChipsModule } from "@angular/material/chips";
import { MatMenuModule } from "@angular/material/menu";

@Component({
  selector: "app-paginate-sort-table",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatPaginatorModule,
    MatSortModule,
    MatChipsModule,
    MatMenuModule,
  ],
  templateUrl: "./paginate-sort-table.component.html",
  styleUrls: ["./paginate-sort-table.component.scss"],
})
export class PaginateSortTableComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input("displayedColumns") displayedColumns: string[];
  @Input("dataSource") dataSource: any;
  @Input("columnsSchema") columnsSchema: any;
  @Input("paginate") paginate: any;
  @Input("totalItems") totalItems: number = 0;
  @Input("tableId") tableId: string;
  @Input("actions") actions_list: any;
  @Input("pageIndex") pageIndex: number = 0;
  @Input("pageSize") pageSize: number = 10;

  @Output() action: EventEmitter<any> = new EventEmitter();
  @Output() performActionOnParent: EventEmitter<any> = new EventEmitter();
  @Output() performOnChangePageOnParent: EventEmitter<any> = new EventEmitter();
  @Output() performSortChangeOnParent: EventEmitter<any> = new EventEmitter();
  page: number = 1;
  showPaginator = false;

  pageSizeOptions: any = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 100];

  ngAfterViewInit(): void {
    if (this.paginate) {
      this.dataSource.paginator = this.paginator;
      this.showPaginator = true;
    }
  }

  // Recebe os dados e delega a execução para o componente pai
  performAction(id: number, name: string, action: any) {
    this.performActionOnParent.emit({ id: id, name: name, action: action });
  }

  performOnChangePage(pe: PageEvent) {
    this.performOnChangePageOnParent.emit(pe);
  }

  performSortChange(sortState: Sort) {
    this.performSortChangeOnParent.emit(sortState);
  }

  parentAction(action: string, data: any) {
    const action_components = [action, data];
    this.action.emit(action_components);
  }

  disableActionBtn(action: any, col: any) {
    const show_in_status = action.show_in_status.map((i: any) => i.toString());
    const enable_to_all_status: any[] = ["*"];
    if (
      JSON.stringify(show_in_status) === JSON.stringify(enable_to_all_status) ||
      show_in_status.includes(col.status.toString())
    ) {
      return false;
    } else {
      return true;
    }
  }
}
